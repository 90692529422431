import "styles/pages/text-page.scss"
import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const StaffPage = () => {
  const title = "Staff"
  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} color="#fff" />
      <div className="text-page-hero"></div>
      <section className="text-page-main">
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-xl-8">
              <h1 className="text-page-main__title">Staff</h1>
              <div className="row">
                <div className="col-md-6">
                  <p className="text-page-main__text">
                    Lorem ipsum dolor sit ametse, consectetuer adipiscing elit,
                    consequatsed diam nonummy nibh euismod tincidunt ut laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim add isminim
                    veniam, quis nostrud exerci tation ullamcorper suscipit
                    lobortis nisl ut aliquip ex ea commodo consequatnisl ut
                    aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet,
                    consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    thendrerit in vulputate
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-page-main__text">
                    Lorem ipsum dolor sit ametse, consectetuer adipiscing elit,
                    consequatsed diam nonummy nibh euismod tincidunt ut laoreet
                    dolore magna aliquam erat volutpat. Ut wisi enim add isminim
                    veniam, quis nostrud exerci tation ullamcorper suscipit
                    lobortis nisl ut aliquip ex ea commodo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default StaffPage
